<template>
  <div class="fultServiceCenter-action">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" content="故障上报" />
    <ml-tip class="scroll-box" :showHeader="false" style="border: 0">
      <el-scrollbar :height="tabberHeight(185)">
        <!-- 表单 -->
        <ml-form
          ref="faultServiceCenterFormRef"
          labelWidth="200px"
          style="width: 1200px; margin: 20px auto 40px"
          :model="faultServiceCenterForm"
          :rules="faultServiceCenterFormRules"
        >
          <!-- 提示标题 -->
          <el-divider content-position="left" style="font-weight: bold">故障信息</el-divider>
          <el-row>
            <el-col :span="12">
              <ml-select
                prop="companyId"
                placeholder="请选择阿米巴"
                :options="unitOptions"
                label="阿米巴:"
                keyName="companyId"
                labelName="companyName"
                valueName="companyId"
                v-model="faultServiceCenterForm.companyId"
                @change="setCompany"
              />
            </el-col>
            <el-col :span="12">
              <!-- 所属项目 -->
              <ml-select
                prop="projectId"
                placeholder="请选择所属项目"
                :options="projectData"
                label="所属项目:"
                keyName="projectId"
                labelName="projectName"
                valueName="projectId"
                v-model="faultServiceCenterForm.projectId"
                @change="searchProject"
              />
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <ml-select
                prop="propertyType"
                placeholder="请选择资产类别"
                :options="propertyTypeList"
                label="资产类别:"
                keyName="label"
                labelName="label"
                valueName="label"
                v-model="faultServiceCenterForm.propertyType"
                @change="propertyTypeChange"
              />
            </el-col>
            <el-col :span="12">
              <ml-select
                prop="deviceType"
                placeholder="请选择设备类型"
                :options="deviceTypeList"
                label="设备类型:"
                keyName="label"
                labelName="label"
                valueName="label"
                v-model="faultServiceCenterForm.deviceType"
                @change="deviceTypeChange"
              />
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <ml-select
                prop="firstUnit"
                placeholder="请输入所属一级单位"
                :options="firstUnitList"
                label="所属一级单位:"
                keyName="label"
                labelName="label"
                valueName="label"
                v-model="faultServiceCenterForm.firstUnit"
                @change="searchfirstUnit"
              />
            </el-col>
            <el-col :span="12">
              <ml-select
                prop="secondUnit"
                placeholder="请输入所属二级单位"
                :options="secondUnitList"
                label="所属二级单位:"
                keyName="label"
                labelName="label"
                valueName="label"
                v-model="faultServiceCenterForm.secondUnit"
                @change="getEquipmentManageata"
              />
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <!-- 设备 -->
              <ml-select
                prop="deviceId"
                placeholder="请选择设备"
                :options="equipmentManageata"
                label="设备:"
                keyName="deviceId"
                labelName="deviceName"
                valueName="deviceId"
                v-model="faultServiceCenterForm.deviceId"
                v-model:node="deviceNode"
              />
              <!-- <ml-input v-model="faultServiceCenterForm.deviceId" prop="deviceId" type="text"/> -->
            </el-col>
            <el-col :span="12">
              <ml-input
                prop="title"
                label="标题:"
                placeholder="请输入标题"
                v-model.trim="faultServiceCenterForm.title"
              />
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <ml-select
                prop="severity"
                placeholder="请选择严重程度"
                :options="severityData"
                label="严重程度:"
                labelName="label"
                valueName="label"
                v-model="faultServiceCenterForm.severity"
              />
            </el-col>
            <el-col :span="12">
              <ml-input
                prop="address"
                label="地点:"
                placeholder="请输入地点"
                v-model.trim="faultServiceCenterForm.address"
              />
            </el-col>
          </el-row>

          <ml-input
            prop="errorDesc"
            label="故障描述:"
            placeholder="请输入故障描述"
            type="textarea"
            :rows="5"
            v-model.trim="faultServiceCenterForm.errorDesc"
          />
          <el-form-item>
            <ml-list fileName="fileName" :list="faultServiceCenterForm.attachVos" />
          </el-form-item>

          <ml-button :showCancel="false" submitText="上传附件" @click-submit="showUploadDia">
            <!-- <ml-upload
              multiple
              autoUpload
              :showFileList="false"
              :action="action"
              :data="{ linkType: 'error' }"
              :headers="{ Authorization: toKen }"
              name="files"
              @on-success="onSuccess"
            >
              <el-button size="medium" icon="el-icon-circle-plus-outline" type="primary">
                上传附件
              </el-button>
            </ml-upload> -->
          </ml-button>

          <ml-button
            submitText="保存"
            submitIcon="el-icon-document-checked"
            cancelIcon="el-icon-upload2"
            cancelText="上报故障"
            cancelType="success"
            native-type="button"
            :disabledCancel="disabledCancel"
            @click-submit="clickSubmitFultServiceCenter"
            @click-cancel="clickSubmitFultServiceCenter(false)"
          >
            <el-button
              size="medium"
              icon="el-icon-circle-close"
              @click="clickCancelFultServiceCenter"
            >
              返回上一页
            </el-button>
          </ml-button>
        </ml-form>
      </el-scrollbar>
    </ml-tip>
    <!-- 上传附件-资源管理器 -->
    <c-upload-dlg
      v-if="uploadConfig.length > 0"
      ref="cUploadDlgRef"
      v-model="uploadData"
      :config="uploadConfig"
    />
  </div>
</template>

<script>
import { computed, nextTick, onMounted, reactive, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { getTabberData, tabberHeight, searchParams, getPropertyTypeList } from '@/utils'
// import { BASEURL, FILEUPLOADURL } from '@API'

export default {
  name: 'FultServiceCenterAction',
  setup() {

    const { commit, getters, dispatch } = useStore()
    const router = useRouter()
    const isBusinessDepartment = computed(() => getters.isBusinessDepartment)
    const toKen = computed(() => getters.getToken)
    /* 开始 表单 **********************************/
    // 详情数据
    const faultServiceCenterFormRef = ref()
    // 表单配置
    const faultServiceCenterForm = reactive({
      companyId: '',
      projectId: '',
      propertyType: '',
      deviceType: '',
      firstUnit: '',
      secondUnit: '',
      deviceId: '',
      title: '',
      severity: '',
      address: '',
      errorDesc: '',
      attachVos: [],
      signName: '',
      deviceName: '',
      province: '',
      city: '',
      area: '',
      latitude: '',
      longitude: ''
    })

    // 资产类型
    const propertyTypeList = ref(getPropertyTypeList())
    // 设备类型
    const deviceTypeList = ref([])
    const propertyTypeChange = () => {
      faultServiceCenterForm.deviceType = ''
      faultServiceCenterForm.deviceId = ''
      faultServiceCenterForm.firstUnit = ''
      faultServiceCenterForm.secondUnit = ''
      secondUnitList.value = []
      fetchgetfirstUnitList()
      getEquipmentManageata()
    }
    // 监听资产类型变化
    watch(
      () => faultServiceCenterForm.propertyType,
      newvalue => {
        deviceTypeList.value = []
        if (newvalue) {
          const newPropertyTypeList = propertyTypeList.value.filter(item => item.label === newvalue)
          deviceTypeList.value = newPropertyTypeList[0].value
        }
      }
    )

    const searchfirstUnit = () => {
      faultServiceCenterForm.secondUnit = ''
      getEquipmentManageata()
    }

    watch(
      () => faultServiceCenterForm.firstUnit,
      () => {
        fetchgetsecondUnitList()
      }
    )

    const deviceTypeChange = () => {
      faultServiceCenterForm.deviceId = ''
      faultServiceCenterForm.firstUnit = ''
      faultServiceCenterForm.secondUnit = ''
      fetchgetfirstUnitList()
      getEquipmentManageata()
    }
    // 一级单位
    const fetchgetfirstUnitList = () => {
      const { companyId, projectId, propertyType, deviceType } = faultServiceCenterForm
      const firstUnitParams = {
        projectId,
        companyId
      }
      if (propertyType) {
        firstUnitParams.propertyType = propertyType
      }
      if (deviceType) {
        firstUnitParams.deviceType = deviceType
      }
      dispatch('fetchgetfirstUnitList', firstUnitParams).then(data => {
        if (data && data.code === 200) {
          if (data.data && data.data.length > 0) {
            firstUnitList.value = data.data.map(item => {
              return {
                label: item
              }
            })
          } else {
            firstUnitList.value = []
          }
        }
      })
    }
    // 二级单位
    const fetchgetsecondUnitList = () => {
      const { companyId, projectId, propertyType, deviceType, firstUnit } = faultServiceCenterForm
      const secondUnitParams = {
        projectId,
        companyId,
        propertyType,
        deviceType,
        firstUnit
      }

      dispatch('fetchgetsecondUnitList', secondUnitParams).then(data => {
        if (data && data.code === 200) {
          if (data.data && data.data.length > 0) {
            secondUnitList.value = data.data.map(item => {
              return {
                label: item
              }
            })
          } else {
            secondUnitList.value = []
          }
        }
      })
    }

    // 类型下拉列表
    const typeData = ref([{ label: '故障' }, { label: '缺陷' }])
    // 严重程度下拉列表
    const severityData = ref([
      { label: '轻微' },
      { label: '一般' },
      { label: '严重' },
      { label: '灾难' }
    ])
    // 单位名下拉列表
    const unitOptions = ref([])
    // 设备选中信息
    const deviceNode = ref({})
    // 所属项目下拉列表
    const projectData = ref([])
    // 一级单位
    const firstUnitList = ref([])
    // 二级单位
    const secondUnitList = ref([])
    // 设备下拉列表
    const equipmentManageata = ref([])
    // 处理所属项目
    const getProjectData = async companyId => {
      const projectParams = { projectStatus: 'Y' }
      if (isBusinessDepartment.value) {
        projectParams.companyId = companyId
      }
      const { data } = await getTabberData(dispatch, 'fetchGetProjectsByCompany', projectParams)
      projectData.value = data || []
    }
    const setCompany = () => {
      faultServiceCenterForm.projectId = ''
      faultServiceCenterForm.deviceId = ''
    }
    // 处理设备
    const getEquipmentManageata = async () => {
      const {
        companyId,
        projectId,
        propertyType,
        deviceType,
        firstUnit,
        secondUnit
      } = faultServiceCenterForm
      const projectParams = {
        projectId,
        companyId
      }
      if (propertyType) {
        projectParams.propertyType = propertyType
      }
      if (deviceType) {
        projectParams.deviceType = deviceType
      }
      if (firstUnit) {
        projectParams.firstUnit = firstUnit
      }
      if (secondUnit) {
        projectParams.secondUnit = secondUnit
      }

      const { data } = await getTabberData(dispatch, 'fetchGetDeviceInfos', projectParams)
      equipmentManageata.value = data || []
    }
    // 监听项目
    const searchProject = () => {
      faultServiceCenterForm.propertyType = ''
      faultServiceCenterForm.deviceType = ''
      faultServiceCenterForm.deviceId = ''
      fetchgetfirstUnitList()
      getEquipmentManageata()
    }

    // 监听单位
    watch(
      () => faultServiceCenterForm.companyId,
      newvalue => {
        getProjectData(newvalue)
      }
    )
    // 监听设备
    watch(
      () => deviceNode.value,
      newvalue => {
        const {
          deviceName,
          province,
          city,
          area,
          latitude,
          longitude,
          address,
          propertyType,
          deviceType,
          firstUnit,
          secondUnit
        } = newvalue
        faultServiceCenterForm.deviceName = deviceName
        faultServiceCenterForm.propertyType = propertyType
        faultServiceCenterForm.deviceType = deviceType
        faultServiceCenterForm.firstUnit = firstUnit
        faultServiceCenterForm.secondUnit = secondUnit
        faultServiceCenterForm.province = province
        faultServiceCenterForm.city = city
        faultServiceCenterForm.area = area
        faultServiceCenterForm.latitude = latitude
        faultServiceCenterForm.longitude = longitude
        faultServiceCenterForm.address = address
      }
    )

    // 表单校验
    const faultServiceCenterFormRules = {
      companyId: [{ required: true, message: '请选择阿米巴 ', trigger: 'blur' }],
      projectId: [{ required: true, message: '请选择所属项目', trigger: 'blur' }],
      propertyType: [{ required: true, message: '请输入资产类别', trigger: 'blur' }],
      deviceType: [{ required: true, message: '请输入设备类型', trigger: 'blur' }],
      deviceId: [{ required: true, message: '请选择设备', trigger: 'blur' }],
      title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
      severity: [{ required: true, message: '请选择严重程度', trigger: 'blur' }],
      address: [{ required: true, message: '请输入地址', trigger: 'blur' }]
    }
    // 提交表单
    const disabledCancel = ref(false)
    const clickSubmitFultServiceCenter = (save = true) => {

      // // eslint-disable-next-line no-empty
      // if(faultServiceCenterForm.deviceId==''){
      //
      //   // eslint-disable-next-line no-empty
      //   for(var i=0;i<equipmentManageata.value.length;i++){
      //     // eslint-disable-next-line no-empty
      //     if(faultServiceCenterForm.deviceName==equipmentManageata.value[0]['deviceName']){
      //       faultServiceCenterForm.deviceId = equipmentManageata.value[0]['deviceId']
      //     }
      //   }
      // }

      const actionName = save ? 'fetchAddDeviceErrorDraft' : 'fetchAddDeviceErrorRecord'
      faultServiceCenterFormRef.value.CustomFormRef.validate()
        .then(() => {
          disabledCancel.value = true
          const faultServiceCenterParams = searchParams(faultServiceCenterForm, [
            'firstUnit',
            'secondUnit'
          ])
          faultServiceCenterParams.deviceInfo = {}
          const { firstUnit, secondUnit } = faultServiceCenterForm
          if (firstUnit) {
            faultServiceCenterParams.deviceInfo.firstUnit = firstUnit
          }
          if (secondUnit) {
            faultServiceCenterParams.deviceInfo.secondUnit = secondUnit
          }
          //
          dispatch(actionName, faultServiceCenterParams).then(data => {
            if (data && data.code === 200) {
              commit('setError', {
                status: true,
                title: data.message,
                message: '正在获取新的数据...',
                type: 'success'
              })
              disabledCancel.value = false
              if (!save) {
                router.push({ path: '/faultServiceCenter' })
              }
            }
          })
        })
        .catch(() => {})
    }
    // 取消表单
    const clickCancelFultServiceCenter = () => {
      router.push({ path: '/faultServiceCenter' })
    }

    const getFaultServiceCenterDetails = () => {
      dispatch('fetchGetDeviceErrorDraft').then(data => {
        if (data && data.code === 200) {
          const { firstUnit, secondUnit } = data.data || {}
          Object.keys(faultServiceCenterForm).map(item => {
            if (data.data) {
              if (['attachVos'].includes(item)) {
                faultServiceCenterForm[item] = data.data[item] || []
              } else if (['firstUnit'].includes(item)) {
                faultServiceCenterForm[item] = firstUnit
              } else if (['secondUnit'].includes(item)) {
                faultServiceCenterForm[item] = secondUnit
              } else {
                faultServiceCenterForm[item] = data.data[item]
              }
            }
          })
        }
      })
    }

    // 上传附件
    // const action = ref(`${BASEURL}${FILEUPLOADURL}`)
    // const onSuccess = files => {
    //   if (files.data) {
    //     faultServiceCenterForm.attachVos = [...faultServiceCenterForm.attachVos, ...files.data]
    //   }
    // }

    /* 结束 用户表单 **********************************/

    /* 2021-12-15 新版上传文件管理器 wml ******************/
    // 上传资源管理器属性
    const cUploadDlgRef = ref()
    const uploadData = ref([])
    const uploadConfig = ref([])

    watch(
      () => uploadData.value,
      newvalue => {
        faultServiceCenterForm.attachVos = [...faultServiceCenterForm.attachVos, ...newvalue]
      }
    )
    // 打开对话框
    const showUploadDia = () => {
      const { companyId, projectId } = faultServiceCenterForm
      uploadData.value = []
      const nArr = []
      if (companyId) {
        nArr.push(companyId)
        if (projectId) {
          nArr.push(projectId)
        }
      }
      uploadConfig.value = [...nArr]
      nextTick(() => {
        cUploadDlgRef.value.uploadDialogRef.showDialog = true
      })
    }
    /* 2021-12-15 新版上传文件管理器 wml ******************/

    onMounted(async () => {
      // 获取单位名
      const { data } = await getTabberData(dispatch, 'fetchGetCompanyInfos', {
        noCompanyCode: 'business_department'
      })
      unitOptions.value = data || []
      if (data.length > 0) {
        faultServiceCenterForm.companyId = data[0].companyId
      }
      await getFaultServiceCenterDetails()
    })
    return {
      faultServiceCenterForm,
      unitOptions,
      projectData,
      faultServiceCenterFormRules,
      clickSubmitFultServiceCenter,
      clickCancelFultServiceCenter,
      tabberHeight,
      faultServiceCenterFormRef,
      setCompany,
      equipmentManageata,
      typeData,
      severityData,
      // onSuccess,
      // action,
      toKen,
      deviceNode,
      uploadData,
      showUploadDia,
      cUploadDlgRef,
      uploadConfig,
      disabledCancel,
      propertyTypeList,
      deviceTypeList,
      propertyTypeChange,
      deviceTypeChange,
      firstUnitList,
      secondUnitList,
      searchProject,
      getEquipmentManageata,
      searchfirstUnit
    }
  }
}
</script>

<style lang="scss">
.fultServiceCenter-action {
  position: relative;
}
</style>
